import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CAMInterceptorService } from './shared/cam-interceptor.service';
import { Error500Component } from './shared/components/error-500/error-500.component';
import { AuthSuccessInterceptorService } from './shared/auth-success-interceptor.service';
import { AuthErrorInterceptorService } from './shared/auth-error-interceptor.service';
import { RedirectComponent } from './shared/components/redirect/redirect.component';
import { catchError, from, retry, firstValueFrom } from 'rxjs';

const RETRY_COUNT = 3;
const routes: Routes = [
    { path: 'access_token', canActivate: [AuthSuccessInterceptorService], component: Error500Component, loadChildren: (): any => import('./shared/error.module').then((module) => module.ErrorModule) },
    { path: 'id_token', canActivate: [AuthSuccessInterceptorService], component: Error500Component, loadChildren: (): any => import('./shared/error.module').then((module) => module.ErrorModule) },
    { path: 'error', canActivate: [AuthErrorInterceptorService], component: Error500Component, loadChildren: (): any => import('./shared/error.module').then((module) => module.ErrorModule) },
    {
        path: 'personal/policy',
        canActivate: [CAMInterceptorService],
        loadChildren: (): any => firstValueFrom(from(import('./auto/auto.module')).pipe(
            retry(RETRY_COUNT),
            catchError((error) => {
                throw new Error(`Loading AutoModule failed after ${RETRY_COUNT} retries`);
            })
        )).then((module: any) => module.AutoModule)
    },
    {
        path: 'personal/property',
        canActivate: [CAMInterceptorService],
        loadChildren: (): any => firstValueFrom(from(import('./property/property.module')).pipe(
            retry(RETRY_COUNT),
            catchError((error) => {
                throw new Error(`Loading PropertyModule failed after ${RETRY_COUNT} retries`);
            })
        )).then((module: any) => module.PropertyModule)
    },
    {
        path: 'personal/powersports',
        canActivate: [CAMInterceptorService],
        loadChildren: (): any => firstValueFrom(from(import('./powersports/powersports.module')).pipe(
            retry(RETRY_COUNT),
            catchError((error) => {
                throw new Error(`Loading PowersportsModule failed after ${RETRY_COUNT} retries`);
            })
        )).then((module: any) => module.PowersportsModule)
    },
    {
        path: 'personal/stub',
        canActivate: [CAMInterceptorService],
        loadChildren: (): any => import('./policy-stub/policy-stub.module').then((module) => module.PolicyStubModule)
    },
    {
        path: 'quick',
        loadChildren: (): any => firstValueFrom(from(import('./quick-add-vehicle/quick-add-vehicle.module')).pipe(
            retry(RETRY_COUNT),
            catchError((error) => {
                throw new Error(`Loading QuickAddVehicleModule failed after ${RETRY_COUNT} retries`);
            })
        )).then((module: any) => module.QuickAddVehicleModule)
    },
    {
        path: 'ivr',
        loadChildren: (): any => firstValueFrom(from(import('./auto/ivr/ivr.module')).pipe(
            retry(RETRY_COUNT),
            catchError((error) => {
                throw new Error(`Loading IvrModule failed after ${RETRY_COUNT} retries`);
            })
        )).then((module: any) => module.IvrModule)
    },
    {
        path: 'qe',
        loadChildren: (): any => firstValueFrom(from(import('./docusign-texting/docusign-texting.module')).pipe(
            retry(RETRY_COUNT),
            catchError((error) => {
                throw new Error(`Loading DocusignTextingModule failed after ${RETRY_COUNT} retries`);
            })
        )).then((module: any) => module.DocusignTextingModule)
    },
    {
        path: 'id-card-texting',
        loadChildren: (): any => firstValueFrom(from(import('./id-texting/id-texting.module')).pipe(
            retry(RETRY_COUNT),
            catchError((error) => {
                throw new Error(`Loading IdTextingModule failed after ${RETRY_COUNT} retries`);
            })
        )).then((module: any) => module.IdTextingModule)
    },
    {
        path: 'qid',
        loadChildren: (): any => firstValueFrom(from(import('./quick-id/quick-id.module')).pipe(
            retry(RETRY_COUNT),
            catchError((error) => {
                throw new Error(`Loading QuickIdModule failed after ${RETRY_COUNT} retries`);
            })
        )).then((module: any) => module.QuickIdModule)
    },
    {
        path: 'personal/umbrella',
        canActivate: [CAMInterceptorService],
        loadChildren: (): any => firstValueFrom(from(import('./umbrella/umbrella.module')).pipe(
            retry(RETRY_COUNT),
            catchError((error) => {
                throw new Error(`Loading UmbrellaModule failed after ${RETRY_COUNT} retries`);
            })
        )).then((module: any) => module.UmbrellaModule)
    },
    {
        path: 'personal/dwelling-fire',
        canActivate: [CAMInterceptorService],
        loadChildren: (): any => firstValueFrom(from(import('./dwelling-fire/dwelling-fire.module')).pipe(
            retry(RETRY_COUNT),
            catchError((error) => {
                throw new Error(`Loading DwellingFireModule failed after ${RETRY_COUNT} retries`);
            })
        )).then((module: any) => module.DwellingFireModule)
    },
    {
        path: 'insurancecontracts',
        loadChildren: (): any => firstValueFrom(from(import('./contract-postings/contract-postings.module')).pipe(
            retry(RETRY_COUNT),
            catchError((error) => {
                throw new Error(`Loading ContractPostingsModule failed after ${RETRY_COUNT} retries`);
            })
        )).then((module: any) => module.ContractPostingsModule)
    },
    {
        path: 'redirect',
        component: RedirectComponent
    },
    { path: '**', redirectTo: 'personal/policy/error-404' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { canceledNavigationResolution: 'computed' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
