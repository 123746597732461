import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoggerService, PageUtilsService, PROPERTY_CONSTANTS } from '@nationwide/dgs-internet-servicing-policy-common';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-personal-policy-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
    // eslint-disable-next-line max-params
    constructor(
        private route: ActivatedRoute,
        private LOGGER: LoggerService,
        private pageUtilsService: PageUtilsService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.LOGGER.info('Redirect loaded');
        this.route.queryParams.subscribe((params: Params) => {
            const destination = params['destination'];
            const isExternalUrl = params['is_external_url'] === 'true';
            this.LOGGER.logCurrentContents();
            if (isExternalUrl) {
                this.LOGGER.info(`Redirect to: ${destination}`);
                this.pageUtilsService.setWindowLocationHref(environment[destination]);
            } else if (PROPERTY_CONSTANTS.redirects[destination]) {
                this.LOGGER.info(`Internal redirect to: ${destination}`);
                this.router.navigate([PROPERTY_CONSTANTS.redirects[destination]], { replaceUrl: true });
            } else {
                this.LOGGER.info(`No destination. Redirecting to account summary page`);
                this.pageUtilsService.setWindowLocationHref(environment.accountSummaryPage);
            }
        });
    }
}
