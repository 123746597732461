import { Injectable } from '@angular/core';
import { APICommonService } from '@nationwide/internet-servicing-angular-services';
import { AutoPolicyAPIService, PolicyDataService, EbiDataService, PolicyChangeDataService, PolicyChangeService, ErrorService, ViewOnlyService, PROPERTY_CONSTANTS } from '@nationwide/dgs-internet-servicing-policy-common';
import { formatDate } from '@angular/common';
import { environment } from '../../../environments/environment';
import jwtDecode from 'jwt-decode';
import { map, switchMap } from 'rxjs/operators';
import { ContentfulService } from '@nationwide/internet-servicing-contentful';
import { OauthClientService } from '../../shared/oauth-client.service';
import { Observable } from 'rxjs';
import { QuickAuthenticateAdditionalOptions } from './quick-authticate-additional-options.interface';
import { EuaQueryParameters } from './eua-query-parameters.interface';

@Injectable()
export class QuickAuthenticateService {
    ttl: number;
    useDevEnvironment = false;

    // eslint-disable-next-line max-params
    constructor(
        private apiCommonService: APICommonService,
        private autoPolicyAPIService: AutoPolicyAPIService,
        private policyDataService: PolicyDataService,
        private ebiDataService: EbiDataService,
        private policyChangeDataService: PolicyChangeDataService,
        private policyChangeService: PolicyChangeService,
        private errorService: ErrorService,
        private viewOnlyService: ViewOnlyService,
        private contentfulService: ContentfulService,
        private oauthClientService: OauthClientService
    ) {
        this.ttl = environment.authorize.ttl;
    }

    callEuaCustomerSearch(additionalQueries: QuickAuthenticateAdditionalOptions): Observable<any> {
        const formattedDateOfBirth = formatDate(new Date(additionalQueries.birthDate), 'yyyy-MM-dd', 'en-US');
        const areaCodeLength = 3;
        const authorizeOptions = this.getDefaultQueryOptions();
        authorizeOptions.auth_id_postalCode = additionalQueries.zipCode;
        authorizeOptions.auth_id_birthDate = formattedDateOfBirth;
        authorizeOptions.auth_id_areaCode = additionalQueries.phoneNumber.slice(0, areaCodeLength);
        authorizeOptions.auth_id_localNumber = additionalQueries.phoneNumber.substring(areaCodeLength, additionalQueries.phoneNumber.length);
        authorizeOptions.auth_id_lastName = additionalQueries.lastName;
        authorizeOptions.auth_method = 'customer-search';
        return this.oauthClientService.authorize(authorizeOptions);
    }

    callEuaNWExpress(additionalQueries: QuickAuthenticateAdditionalOptions): Observable<any> {
        const authorizeOptions = this.getDefaultQueryOptions();
        authorizeOptions.auth_id_postalCode = additionalQueries.zipCode;
        authorizeOptions.auth_id_dateOfBirth = additionalQueries.birthDate;
        authorizeOptions.auth_method = 'nw-express';
        if (additionalQueries.agreementNumber) {
            authorizeOptions.auth_id_agreementNumber = additionalQueries.agreementNumber;
        } else {
            authorizeOptions.auth_id_phoneNumber = additionalQueries.phoneNumber;
        }
        return this.oauthClientService.authorize(authorizeOptions);
    }

    callEuaQuickServicing(additionalQueries: QuickAuthenticateAdditionalOptions): Observable<any> {
        const authorizeOptions = this.getDefaultQueryOptions();
        authorizeOptions.auth_method = environment.idTexting.quickServicingAuthMethod;
        authorizeOptions.auth_id_guid = additionalQueries.guid;
        authorizeOptions.auth_id_postalCode = additionalQueries.zipCode;
        return this.oauthClientService.authorize(authorizeOptions);
    }

    callEuaVisualIVR(additionalQueries: QuickAuthenticateAdditionalOptions): Observable<any> {
        const authorizeOptions = this.getDefaultQueryOptions();
        authorizeOptions.auth_method = 'visual-ivr';
        authorizeOptions.scope = 'openid';
        authorizeOptions.ttl = 3600;
        const formattedDateOfBirth = additionalQueries.birthDate ? formatDate(additionalQueries.birthDate, 'yyyy-MM-dd', 'en-US') : additionalQueries.birthDate;
        authorizeOptions.auth_id_zipCode = additionalQueries.zipCode;
        authorizeOptions.auth_id_dateOfBirth = formattedDateOfBirth;
        authorizeOptions.auth_id_uuid = additionalQueries.uuid;
        return this.oauthClientService.authorize(authorizeOptions);
    }

    getDecodedJwt(): any {
        let idToken = '';
        idToken = sessionStorage.getItem('id_token');
        return idToken ? jwtDecode(idToken) : idToken;
    }

    getDefaultQueryOptions(): EuaQueryParameters {
        return {
            client_id: environment.authorize.client_id,
            nonce: environment.authorize.nonce,
            redirect_uri: `${location.protocol}//${location.host}${environment.authorize.oauthRedirectHandlerPath}`,
            response_type: environment.authorize.responseType,
            scope: environment.authorize.scope,
            state: 'knowledgeState',
            realm: 'unidentified',
            message_id: this.apiCommonService.generateTransactionId(),
            ttl: environment.authorize.ttl
        };
    }

    getFilteredMaskedAgreements(maskedAgreements): Array<any> {
        let filteredAgreements;
        if (maskedAgreements?.length > 0) {
            if (sessionStorage.getItem('isIVR')) { // check for IVR flow with data
                filteredAgreements = maskedAgreements.filter((agreement) => agreement.party_role_type === 'Owner' && agreement.source_system === 'PolicyCenter');
            } else { // this catches the quick add/replace flow in the quick-policy-picker.component
                filteredAgreements = maskedAgreements.filter((agreement) => agreement.party_role_type === 'Owner' && agreement.product_type === 'Auto' && agreement.line_of_business === 'Personal Auto' && agreement.source_system === 'PolicyCenter');
            }
        }
        return filteredAgreements;
    }

    getKnowledgeBasedOAuthCall(addendumString): string {
        const scope = 'test';
        const currentUrl = [location.protocol, '//', location.host, location.pathname].join('');
        const oAuthEndpoint = `${environment.authorize.oauthEndpoint
            }?client_id=${environment.authorize.client_id
            }&nonce=${environment.authorize.nonce
            }&redirect_uri=${currentUrl
            }&response_type=${environment.authorize.responseType
            }&scope=${scope
            }&realm=unidentified` +
            `&message_id=${this.apiCommonService.generateTransactionId()
            }&state=knowledgeState` +
            `&ttl=${this.ttl
            }${addendumString}`;
        return oAuthEndpoint;
    }

    idtEuaUrl(params: NwExpressAuthParams): string {
        const queryString = `&auth_id_postalCode=${params.zipCode}&auth_id_guid=${params.guid}&auth_method=nw-express`;
        return this.getKnowledgeBasedOAuthCall(queryString);
    }

    loadPolicy(): any {
        const pageData: { pageLevelErrorMessage: string; isLoadComplete: boolean; navigateTo: string } = <any>{};

        return this.autoPolicyAPIService.retrievePolicyInformationIndividual().pipe(switchMap((policyInformationData) => this.autoPolicyAPIService.retrievePolicyChangeId().pipe(map((policyChangeIdData) => {
            const viewOnly = this.viewOnlyService.isViewOnly(policyInformationData);
            if (viewOnly) {
                pageData.pageLevelErrorMessage = this.contentfulService.getContentfulContentById('viewOnly');
                window.scrollTo(0, 0);
                pageData.isLoadComplete = true;
            } else if (policyChangeIdData?.[0]?.policyChangeId) {
                this.policyChangeService.clearPolicyChange(true).subscribe(() => {
                    if (!this.policyChangeDataService.policyChangeAllowed) {
                        pageData.pageLevelErrorMessage = this.contentfulService.getContentfulContentById('viewOnly');
                        window.scrollTo(0, 0);
                        pageData.isLoadComplete = true;
                        this.errorService.goToHardFall(PROPERTY_CONSTANTS.quickAddErrorMessages.withdrawError);
                        pageData.navigateTo = PROPERTY_CONSTANTS.quickNavPrefix + PROPERTY_CONSTANTS.hardFall;
                    }
                });
            } else {
                this.ebiDataService.ecn = sessionStorage.getItem('ecn');
                sessionStorage.removeItem('ecn');
                this.policyDataService.environment = environment;
                if (PROPERTY_CONSTANTS.statesThatRequireDVA[this.policyDataService.baseState]) {
                    this.errorService.goToHardFall(PROPERTY_CONSTANTS.quickAddErrorMessages.dvaError);
                    pageData.navigateTo = PROPERTY_CONSTANTS.quickNavPrefix + PROPERTY_CONSTANTS.hardFall;
                } else {
                    pageData.navigateTo = PROPERTY_CONSTANTS.quickNavPrefix + PROPERTY_CONSTANTS.quickAddVehicle;
                }
            }
            return pageData;
        }))));
    }

    nwExpressAuth(params: NwExpressAuthParams): string {
        const variableSearchParam = params.agreementNumber ? `auth_id_agreementNumber=${params.agreementNumber}` : `auth_id_phoneNumber=${params?.phoneNumber}`;
        const queryString = `&auth_id_postalCode=${params.zipCode}&auth_id_dateOfBirth=${params.birthDate}&${variableSearchParam}&auth_method=nw-express`;
        return this.getKnowledgeBasedOAuthCall(queryString);
    }

    searchByPhoneNumber({ phoneNumber, postalCode, birthDate, lastName }: { phoneNumber; postalCode; birthDate; lastName }): string {
        const formattedDateOfBirth = formatDate(new Date(birthDate), 'yyyy-MM-dd', 'en-US');
        const AREA_CODE_LENGTH = 3;
        const addendumString = `&auth_id_areaCode=${phoneNumber.slice(0, AREA_CODE_LENGTH)
            }&auth_id_localNumber=${phoneNumber.substring(AREA_CODE_LENGTH, phoneNumber.length)
            }&auth_id_postalCode=${postalCode
            }&auth_id_birthDate=${formattedDateOfBirth
            }&auth_id_lastName=${lastName
            }&auth_method=customer-search`;
        return this.getKnowledgeBasedOAuthCall(addendumString);
    }

    setSessionStorage(maskedPolicyNumber): void {
        this.autoPolicyAPIService.clearCache();
        sessionStorage.setItem('pcEnv', environment.policyCenterHeaderURL);
        sessionStorage.setItem('policyNumber', maskedPolicyNumber);
        const token = sessionStorage.getItem('tokenDetails') ? sessionStorage.getItem('tokenDetails').split(',')[0] : '';
        this.policyDataService.accessToken = token;
        if (this.useDevEnvironment) {
            environment.APIs.apigeeEnvironment = environment.APIs.apigeeEnvironment.replace('test', 'dev');
        } else {
            environment.APIs.apigeeEnvironment = environment.APIs.apigeeEnvironment.replace('dev', 'test');
        }
    }
}

export interface NwExpressAuthParams {
    agreementNumber?: string;
    phoneNumber?: string;
    birthDate?: string;
    zipCode?: string;
    guid?: string;
}
