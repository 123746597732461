import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { LoggingInterceptorService } from './shared/logging-interceptor.service';
import { AppSharedService } from './shared/app-shared.service';
import { CAMInterceptorService } from './shared/cam-interceptor.service';
import { InternetServicingPolicyErrorHandler } from './shared/internet-servicing-policy-error-handler';
import { AuthErrorInterceptorService } from './shared/auth-error-interceptor.service';
import { AuthSuccessInterceptorService } from './shared/auth-success-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InternetServicingContentfulModule } from '@nationwide/internet-servicing-contentful';
import { InternetServicingAngularPipesModule } from '@nationwide/internet-servicing-angular-pipes';
import { AppLoadModule } from './app-load.module';
import { DpimInformationModule } from './shared/dpim-information.module';
import { RedirectComponent } from './shared/components/redirect/redirect.component';

@NgModule({
    imports: [
        AppRoutingModule,
        AppLoadModule,
        BrowserModule,
        HttpClientModule,
        InternetServicingAngularPipesModule,
        InternetServicingContentfulModule,
        BrowserAnimationsModule,
        DpimInformationModule
    ],
    declarations: [
        AppComponent,
        RedirectComponent
    ],
    providers: [
        AuthErrorInterceptorService,
        AuthSuccessInterceptorService,
        AppSharedService,
        CAMInterceptorService,
        { provide: 'policyCommonConfig', useValue: environment },
        { provide: 'policyRequestsConfig', useValue: environment },
        { provide: 'vinConfig', useValue: environment },
        { provide: 'documentsConfig', useValue: environment },
        { provide: 'contentfulConfig', useValue: environment },
        { provide: 'pbsConfig', useValue: environment },
        { provide: ErrorHandler, useClass: InternetServicingPolicyErrorHandler },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: 'ctAuthConfig', useFactory: (): any => environment.authorize },
        { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptorService, multi: true },
        environment.ENVIRONMENT_SPECIFIC_PROVIDERS
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
